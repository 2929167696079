import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = ({ message = "Page Not Found", statusCode = "404" }) => {
    return (
        <div className="h-screen flex items-center justify-center bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-red-500">{statusCode}</h1>
                <p className="text-xl mt-4 mb-8 text-gray-800">{message}</p>
                <Link to="/" className="px-6 py-2 rounded bg-blue-500 text-white hover:bg-blue-700 transition duration-300">
                    Gå till startsidan
                </Link>
            </div>
        </div>
    );
};

export default ErrorPage;
