import React, { useState, useEffect } from 'react';
import { fetchSavedCalculations, deleteFinanceDetail } from '../../services/financeService';
import { FaCheckCircle, FaTimesCircle, FaTrash, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { userService } from '../../services/userService';
import FinanceResults from '../FinanceResults/FinanceResults';
import { ClipLoader } from 'react-spinners';
import FinanceChart from '../FinanceChart/FinanceChart';
import CalculationsChart from '../FinanceChart/CalculationsChart';
import { Tooltip } from 'react-tooltip';

const SavedCalculations = () => {
  const [calculations, setCalculations] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await userService.fetchUserProfile();
        console.log('User Data:', user);
        setUserData(user);
        const data = await fetchSavedCalculations();
        setCalculations(data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Är du säker på att du vill radera denna beräkning?')) {
      try {
        await deleteFinanceDetail(id);
        setCalculations(calculations.filter((calc) => calc._id !== id));
      } catch (error) {
        console.error('Ett fel uppstod vid radering av beräkning', error);
      }
    }
  };

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mt-10">
        <h2 className="text-3xl font-bold mb-5 text-center">Översikt av alla sparade beräkningar</h2>
        <CalculationsChart calculations={calculations} />
      </div>
      <h1 className="text-4xl font-bold mb-10 text-center">Sparade bilar</h1>
      {!userData && <ClipLoader color="#dddddd" />}
      {calculations.map((calc) => (
        <div key={calc._id} className="bg-white rounded-lg shadow p-6 mb-4">
          <div className="flex justify-between items-center" onClick={() => toggleDropdown(calc._id)}>
            <div className="flex items-center">
              <p className="text-xl font-semibold mr-2">{calc.carName}</p>
              {userData && userData.cashOnHand >= calc.upfrontPayment && userData.monthlyIncome - calc.monthlyTotalCost > 0 ? (
                <>
                  <FaCheckCircle className="text-green-500" size={24} data-tooltip-id={`checkmark-${calc._id}`} />
                  <Tooltip id={`checkmark-${calc._id}`} place="right" effect="solid">Din inkomst tillåter bilen</Tooltip>
                </>
              ) : (
                <>
                  <FaTimesCircle className="text-red-500" size={24} data-tooltip-id={`crossmark-${calc._id}`} />
                  <Tooltip id={`crossmark-${calc._id}`} place="right" effect="solid">Din inkomst tillåter inte bilen</Tooltip>
                </>
              )}
            </div>
            <div className="flex items-center">
              <button
                onClick={() => toggleDropdown(calc._id)}
                className="text-accent hover:text-accent3 transition duration-300 ease-in-out p-1"
                title={dropdownOpen === calc._id ? 'Dölj detaljer' : 'Visa detaljer'}
              >
                {dropdownOpen === calc._id ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
              </button>
            </div>
          </div>
          {dropdownOpen === calc._id && (
            <>
              <button
                onClick={() => handleDelete(calc._id)}
                className="text-red-500 hover:text-red-700 transition duration-300 ease-in-out p-1"
                title="Radera beräkning"
              >
                <FaTrash size={20} />
              </button>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex justify-center items-center">
                  <div className="w-full h-full flex justify-center items-center">
                    <FinanceChart calc={calc} />
                  </div>
                </div>
                <div className="grid gap-4">
                  <FinanceResults calc={calc} />
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default SavedCalculations;
