const calculateFinanceDetails = (values) => {
  const carPrice = parseFloat(values.carPrice) // Ensure it's a number
  const upfrontPayment = parseFloat(values.upfrontPayment); // Ensure it's a number
  const loanAmount = carPrice - upfrontPayment; // Amount to be borrowed after upfront payment
  const totalPayments = values.loanTerm; // Total number of monthly payments
  const loanTerm = values.loanTerm
  const residualValue = parseFloat(values.residualValue)
  const realisticResidual = parseFloat(values.realisticResidual)
  const yearlyMiles = values.yearlyMiles
  const monthlyMiles = yearlyMiles / 12;
  const monthlyInsurance = values.monthlyInsurance;
  const yearlyTax = values.yearlyTax;
  const fuelConsumption = values.fuelConsumption;
  const carName = values.carName
  const licensePlate = values.licensePlate

  const adjustedPrincipal = loanAmount - residualValue;
  
  //Monthlys
  const monthlyInterestRate = (values.interestRate / 100) / 12 * loanAmount; // Monthly interest rate
  const hiddenSparande = (adjustedPrincipal /totalPayments);
  const monthlyPayment = (monthlyInterestRate) + (adjustedPrincipal /totalPayments)
  const monthlyFuel = fuelConsumption * monthlyMiles * 19
  const monthlyTax = yearlyTax / 12
  const monthlyTotalCost = monthlyFuel + monthlyTax + monthlyInsurance + monthlyPayment

  //TCO
  const totalInterest = (monthlyInterestRate * totalPayments);
  const dealersCut = realisticResidual * 0.1;
  const carDepriciation = carPrice - realisticResidual
  const totalCostFinance = dealersCut + totalInterest + (carPrice - realisticResidual)
  const totalCostMonthly = loanTerm * monthlyTotalCost
  const TCO = ((monthlyFuel + monthlyTax + monthlyInsurance ) *  loanTerm ) + carDepriciation + totalInterest + dealersCut

    console.log(carPrice, upfrontPayment, loanAmount)
    return {
      monthlyPayment: monthlyPayment.toFixed(0),
      totalInterest: totalInterest.toFixed(0),
      monthlyInterestRate: monthlyInterestRate.toFixed(0),
      hiddenSparande: hiddenSparande.toFixed(0),
      dealersCut: dealersCut.toFixed(0),
      monthlyInsurance: monthlyInsurance.toFixed(0),
      yearlyTax: yearlyTax.toFixed(0),
      monthlyFuel: monthlyFuel.toFixed(0),
      monthlyTax: monthlyTax.toFixed(0),
      totalCostFinance: totalCostFinance.toFixed(0),
      monthlyTotalCost: monthlyTotalCost.toFixed(0),
      upfrontPayment: upfrontPayment.toFixed(0),
      carPrice: carPrice.toFixed(0),
      residualValue: residualValue.toFixed(0),
      realisticResidual: realisticResidual.toFixed(0),
      carDepriciation: carDepriciation.toFixed(0),
      loanAmount: loanAmount,
      carName: carName,
      licensePlate: licensePlate,
      TCO: TCO.toFixed(0),
      totalCostMonthly: totalCostMonthly.toFixed(0),
      loanTerm: loanTerm.toFixed(0),
      yearlyMiles: yearlyMiles.toFixed(0)
    };
  };

  export default calculateFinanceDetails
  
  