import React, { useState } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import API_BASE_URL from '../config/apiConfig';

const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${API_BASE_URL}/api/users/forgot-password`, { email });
            setLoading(false);
            setMessage(response.data.message);
        } catch (error) {
            setLoading(false);
            const errorMessage = error.response?.data.message || 'Error sending password reset email. Please try again.';
            setError(errorMessage);
            console.error('Password Reset Request Error', errorMessage);
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10">
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                {loading && <ClipLoader color="#0000ff" />}
                <h2 className="text-3xl font-semibold mb-5">Återställ lösenord</h2>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                    />
                </div>
                {error && <p className="text-red-500 text-xs italic mb-3">{error}</p>}
                {message && <p className="text-green-500 text-xs italic mb-3">{message}</p>}
                <div className="flex items-center justify-between">
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Skicka återställningslänk
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PasswordResetRequest;
