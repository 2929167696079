// FormValuesChangeDetector.js
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FormValuesChangeDetector = ({ setIsCalculated }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    setIsCalculated(false);
  }, [values, setIsCalculated]);

  return null; // This component does not render anything
};

export default FormValuesChangeDetector;
