import React from 'react';
import { FaCalculator, FaClock, FaRegThumbsUp } from 'react-icons/fa';

export const Features = () => {
  return (
    <section id="about" className="bg-white py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-primary font-primary">Våra funktioner</h2>
        <div className="flex flex-wrap justify-center">
          <Feature icon={FaCalculator} title="Exakt beräkning" description="Få exakta kostnadsuppskattningar baserade på din bilmodell och användning." />
          <Feature icon={FaClock} title="Tidsbesparande" description="Spara tid med en omfattande kostnadsanalys på bara några klick." />
          <Feature icon={FaRegThumbsUp} title="Anpassade rekommendationer" description="Få skräddarsydda rekommendationer för att minska dina bilkostnader." />
        </div>
      </div>
    </section>
  );
};

const Feature = ({ icon: Icon, title, description }) => (
  <div className="w-full md:w-1/3 text-center p-6">
    <Icon className="text-5xl mx-auto mb-4 text-primary" />
    <h3 className="text-xl font-bold mb-2 text-secondary font-primary">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);
