import React from 'react';
import { FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer id="contact" className="bg-primary text-white py-6">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap justify-between items-center">
          <div className="text-sm">
            <Link to="/" className="mr-4 hover:underline">Hem</Link>
            <Link to="/blog" className="mr-4 hover:underline">Blogg</Link>
            <Link to="/login" className="mr-4 hover:underline">Logga in</Link>
            <Link to="/signup" className="mr-4 hover:underline">Skapa konto</Link>
          </div>
          <div className="flex space-x-4">
            <Link to="https://www.tiktok.com/@carculated"><FaTiktok className="text-xl" /></Link>
          </div>
        </div>
      </div>
    </footer>
  );
};