import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import API_BASE_URL from '../config/apiConfig';

const API_URL = `${API_BASE_URL}/api/users/reset-password`;

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  /*const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return regex.test(password);
};*/

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Lösenorden matchar inte');
      return;
    }
    /*if (!validatePassword(password)) {
      setError('Lösenordet behöver vara minst 8 tecken långt, en stor bokstav, och en siffra.');
      return;
    }*/
    setLoading(true);
    try {
      await axios.post(`${API_URL}?token=${token}`, { password });
      setLoading(false);
      setSuccess('Lösenordet har återställts framgångsrikt!');
      setTimeout(() => {
        navigate('/login');
      }, 3000); // Redirect to login after 3 seconds
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data.message || 'Misslyckades att återställa lösenordet. Försök igen.';
      setError(errorMessage);
      console.error('Reset Password Error', errorMessage);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {loading && <ClipLoader color="#0000ff" />}
        <h2 className="text-3xl font-semibold mb-5">Återställ lösenord</h2>
        {error && <p className="text-red-500 text-xs italic mb-3">{error}</p>}
        {success && <p className="text-green-500 text-xs italic mb-3">{success}</p>}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Nytt lösenord
          </label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
            Bekräfta lösenord
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="confirmPassword"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={loading}
          >
            Återställ lösenord
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
