// src/components/FinanceForm/validationSchema.js
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    carPrice: Yup.number().min(10000, 'Minimum av 10 000 kr').required('Obligatoriskt'),
    interestRate: Yup.number().min(0.1, 'Minimum av 0,1%').max(25, 'Maximum av 25%').required('Obligatoriskt'),
    loanTerm: Yup.number().min(1, 'Minst 1 månad').max(168, 'Maximalt 168 månader').required('Obligatoriskt'),
    upfrontPayment: Yup.number().required('Obligatoriskt').test(
      'is-20-percent', 
      'Kontantinstatsen måste vara minst 20% av lånebeloppet, och maximalt hela bilens pris', 
      function(value) {
        return value >= this.parent.carPrice * 0.2 && value <= this.parent.carPrice;
      }
    ),
    residualValue: Yup.number().required('Obligatoriskt').test(
      'is-45-to-60-percent', 
      'Restvärdet måste vara mellan 0% och 60% av bilens värde', 
      function(value) {
        return value >= this.parent.carPrice * 0 && value <= this.parent.carPrice * 0.6;
      }
    ),
    fuelConsumption: Yup.number().min(0, 'Minst 0.0L/mil').max(5, 'Maximalt 5L/mil').required('Obligatoriskt'),
    yearlyTax: Yup.number().max(50000, 'Maximalt 50.000kr').required('Obligatoriskt'),
    monthlyInsurance: Yup.number().min(50, 'Minst 50kr').max(50000, 'Maximalt 50.000kr').required('Obligatoriskt'),
  });
  

export default validationSchema;
