import React from 'react';
import { FaCar, FaMoneyBillWave, FaCalculator } from 'react-icons/fa';

const FinanceResults = ({ calc }) => {
  return (
    <div className="grid grid-cols-1 gap-4">
      <div>
        <p className="text-lg font-medium font-primary flex items-center"><FaCar className="mr-2" />Egenskaper</p>
        <a href={`https://www.car.info/sv-se/license-plate/S/${calc.licensePlate}`} target="_blank" rel="noreferrer">
          <p className="text-sm text-accent3">Regnr: <span className="font-bold">{calc.licensePlate}</span></p>
        </a>
        <p className="text-sm">Bilpris: <span className="font-bold">{calc.carPrice} kr</span></p>
        <p className="text-sm">Kontantinsats: <span className="font-bold">{calc.upfrontPayment} kr</span></p>
        <p className="text-sm">Restvärde finans: <span className="font-bold">{calc.residualValue} kr</span></p>
        <p className="text-sm">Real. restvärde: <span className="font-bold">{calc.realisticResidual} kr</span></p>
        <p className="text-sm">Låneperiod: <span className="font-bold">{calc.loanTerm} månader</span></p>
        <p className="text-sm">Körsträcka per år: <span className="font-bold">{calc.yearlyMiles} mil</span></p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-xl font-medium font-primary flex items-center"><FaMoneyBillWave className="mr-2" />Utgifter</p>
          <p className="text-lg">Totalt: <span className="font-bold">{calc.monthlyTotalCost} kr</span></p>
          <p className="text-sm">Finans: <span className="font-bold">{calc.monthlyPayment} kr</span></p>
          <p className="text-xs">- varav ränta: <span className="font-bold">{calc.monthlyInterestRate} kr</span></p>
          <p className="text-xs">- varav amortering <span className="font-bold">{calc.hiddenSparande} kr</span></p>
          <p className="text-sm">Försäkring: <span className="font-bold">{calc.monthlyInsurance} kr</span></p>
          <p className="text-sm">Bränsle: <span className="font-bold">{calc.monthlyFuel} kr</span></p>
          <p className="text-sm">Skatt: <span className="font-bold">{calc.monthlyTax} kr</span></p>
        </div>
        <div>
          <p className="text-xl font-medium font-primary flex items-center"><FaCalculator className="mr-2" />Life-time kostnader</p>
          <p className="text-lg">TCO: <span className="font-bold">{calc.TCO} kr</span></p>
          <p className="text-sm">Total ränta: <span className="font-bold">{calc.totalInterest} kr</span></p>
          <p className="text-sm">Dealers-cut: <span className="font-bold">{calc.dealersCut} kr</span></p>
          <p className="text-sm">Värdetapp: <span className="font-bold">{calc.carDepriciation} kr</span></p>
          <p className="text-sm">Running costs: <span className="font-bold">{calc.totalCostMonthly} kr</span></p>
        </div>
      </div>
    </div>
  );
};

export default FinanceResults;
