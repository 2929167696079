import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const FinanceChart = ({ calc }) => {
  const chartData = {
    labels: ['Ränta', 'Amortering', 'Bränsle', 'Försäkring', 'Skatt'],
    datasets: [
      {
        label: 'Kostnad',
        data: [
          calc.monthlyInterestRate || 0,
          calc.hiddenSparande || 0,
          calc.monthlyFuel || 0,
          calc.monthlyInsurance || 0,
          calc.monthlyTax || 0,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(130, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(130, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div className="w-full h-auto" style={{ maxHeight: '400px' }}>
      <Doughnut data={chartData} options={chartOptions} />
    </div>
  );
};

export default FinanceChart;
