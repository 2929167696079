import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/verify-email?token=${token}`)
      .then((res) => {
        navigate('/login', { state: { success: 'Din e-post har verifierats. Du kan nu logga in.' } });
      })
      .catch((err) => {
        navigate('/login', { state: { error: 'Misslyckades med att verifiera e-post. Försök igen.' } });
        console.error(err);
      });
  }, [token, navigate]);

  return (
    <div>
      <h1>Verifierar din e-post...</h1>
    </div>
  );
};

export default VerifyEmail;
