import React from 'react';

export const Testimonials = () => {
  return (
    <section id="testimonials" className="bg-white py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-primary font-primary">Vad våra användare säger</h2>
        <div className="flex flex-wrap justify-center">
          <Testimonial
            quote="Den här kalkylatorn hjälpte mig att förstå alla kostnader innan jag köpte min bil. Rekommenderas varmt!"
            author="Anna Svensson"
          />
          <Testimonial
            quote="Enkel att använda och mycket informativ. Gjorde mig mycket tryggare i mitt val av bil!"
            author="Erik Johansson"
          />
        </div>
      </div>
    </section>
  );
};

const Testimonial = ({ quote, author }) => (
  <div className="w-full md:w-1/2 text-center p-6">
    <p className="text-lg italic mb-4">"{quote}"</p>
    <h4 className="text-md font-bold text-secondary">– {author}</h4>
  </div>
);
