import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { userService } from '../../services/userService'; // Make sure the path is correct
import validationSchema from './validationSchema';
import calculateFinanceDetails from './calculateFinanceDetails';
import { saveFinanceDetails } from '../../services/financeService';
import FormValuesChangeDetector from '../FinanceForm/formValuesChanged';
import FinanceResults from '../FinanceResults/FinanceResults';
import FinanceChart from '../FinanceChart/FinanceChart';

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
  margin-top: 10px;
`;

const StyledThumb = styled.div`
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  top: -5px;
`;

const Thumb = (props, state) => <StyledThumb {...props}>    {isNaN(state.valueNow) ? '-' : state.valueNow}
</StyledThumb>;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${props => (props.index === 2 ? '#f00' : props.index === 1 ? '#ddd' : '#13293D')};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const FinanceForm = () => {
  const [user, setUser] = useState(null);
  const [isCalculated, setIsCalculated] = useState(false);
  const [financeDetails, setFinanceDetails] = useState({});
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [initialValues, setInitialValues] = useState({
    carPrice: '',
    interestRate: 7.95,
    loanTerm: 36,
    upfrontPayment: '',
    residualValue: '',
    fuelConsumption: '',
    yearlyTax: '',
    monthlyInsurance: '',
    yearlyMiles: 1500,
    realisticResidual: '',
    carName: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await userService.fetchUserProfile();
        setUser(userData);
        setInitialValues(prevValues => ({
          ...prevValues,
          upfrontPayment: userData.cashOnHand > 0 ? userData.cashOnHand : prevValues.upfrontPayment
        }));
      } catch (error) {
        console.error('Error fetching user data', error);
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async () => {
    setSaveSuccess(false); // Reset success message state
    setSaveError(''); // Reset error message state
    try {
      await saveFinanceDetails(financeDetails); // Save calculated details
      setSaveSuccess(true); // Set success message state to true if save is successful
      setSaveError(''); // Ensure no error message is shown on success
    } catch (error) {
      console.error('Error saving finance details:', error);
      setSaveSuccess(false); // Ensure success message is not shown on error
      setSaveError(error.response?.data.message || 'Misslyckades att spara beräkningen'); // Show error message from response
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-8 m-4 max-w-8xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          const result = calculateFinanceDetails(values);
          setFinanceDetails(result);
          setIsCalculated(true);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <FormValuesChangeDetector setIsCalculated={setIsCalculated} />
            <div className="space-y-4">
              <span className="text-xl font-medium font-primary">Finansiering</span>
              <div>
                <label htmlFor="carName" className="block text-sm font-medium text-gray-700">Namn</label>
                <Field name="carName" type="text" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
                <ErrorMessage name="carName" component="div" className="text-red-500 text-sm" />
              </div>
              <div>
                <label htmlFor="licensePlate" className="block text-sm font-medium text-gray-700">Reg. nr</label>
                <Field name="licensePlate" type="text" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
                <ErrorMessage name="licensePlate" component="div" className="text-red-500 text-sm" />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="carPrice" className="block text-sm font-medium text-gray-700">Bilpris</label>
                  <Field
                    name="carPrice"
                    type="number"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    onChange={e => {
                      const value = parseInt(e.target.value, 10);
                      setFieldValue("carPrice", value);
                      setFieldValue("upfrontPayment", Math.round(value * 0.2));
                      setFieldValue("residualValue", Math.round(value * 0.5));
                      setFieldValue("realisticResidual", Math.round(value * 0.8));
                    }}
                  />
                  <ErrorMessage name="carPrice" component="div" className="text-red-500 text-sm" />
                </div>
                <div>
                  <label htmlFor="interestRate" className="block text-sm font-medium text-gray-700">Räntesats (%)</label>
                  <div className="space-y-2">
                    <StyledSlider
                      min={0}
                      max={20}
                      step={0.1}
                      value={values.interestRate}
                      onChange={(value) => setFieldValue('interestRate', value)}
                      renderTrack={Track}
                      renderThumb={Thumb}
                    />
                    <Field
                      name="interestRate"
                      type="number"
                      step="0.1"
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      value={values.interestRate}
                      onChange={(e) => setFieldValue('interestRate', parseFloat(e.target.value))}
                    />
                    <ErrorMessage name="interestRate" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>
                <div>
                  <label htmlFor="loanTerm" className="block text-sm font-medium text-gray-700">Lånets löptid (mån)</label>
                  <div className="space-y-2">
                    <StyledSlider
                      min={12}
                      max={84}
                      step={12}
                      marks={[12, 24, 36, 48, 60, 72, 84]}
                      value={values.loanTerm}
                      onChange={(value) => setFieldValue('loanTerm', value)}
                      renderTrack={Track}
                      renderThumb={Thumb}
                    />
                    <Field
                      name="loanTerm"
                      type="number"
                      step="1"
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      value={values.loanTerm}
                      onChange={(e) => setFieldValue('loanTerm', parseInt(e.target.value, 10))}
                    />
                    <ErrorMessage name="loanTerm" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>
                <div>
                  <label htmlFor="upfrontPayment" className="block text-sm font-medium text-gray-700">Kontantinsats</label>
                  <div className="space-y-2">
                    <StyledSlider
                      min={20}
                      max={100}
                      step={1}
                      value={(values.upfrontPayment / values.carPrice) * 100}
                      onChange={(value) => setFieldValue('upfrontPayment', Math.round((values.carPrice * value) / 100))}
                      renderTrack={Track}
                      renderThumb={Thumb}
                    />
                    <div className="flex space-x-2">
                      <Field
                        name="upfrontPayment"
                        type="number"
                        step="1000"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        value={values.upfrontPayment}
                        onChange={(e) => setFieldValue('upfrontPayment', parseInt(e.target.value, 10))}
                      />
                    </div>
                  </div>
                  <ErrorMessage name="upfrontPayment" component="div" className="text-red-500 text-sm" />
                </div>
                <div>
                  <label htmlFor="residualValue" className="block text-sm font-medium text-gray-700">Restvärde</label>
                  <div className="space-y-2">
                    <StyledSlider
                      min={0}
                      max={80}
                      step={1}
                      value={(values.residualValue / values.carPrice) * 100}
                      onChange={(value) => setFieldValue('residualValue', Math.round((values.carPrice * value) / 100))}
                      renderTrack={Track}
                      renderThumb={Thumb}
                    />
                    <div className="flex space-x-2">
                      <Field
                        name="residualValue"
                        type="number"
                        step="1000"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        value={values.residualValue}
                        onChange={(e) => setFieldValue('residualValue', parseInt(e.target.value, 10))}
                      />                    </div>
                  </div>
                  <ErrorMessage name="residualValue" component="div" className="text-red-500 text-sm" />
                </div>
                <div>
                  <label htmlFor="realisticResidual" className="block text-sm font-medium text-gray-700">Realistiskt värde efter period</label>
                  <div className="space-y-2">
                    <StyledSlider
                      min={0}
                      max={150}
                      step={1}
                      value={(values.realisticResidual / values.carPrice) * 100}
                      onChange={(value) => setFieldValue('realisticResidual', Math.round((values.carPrice * value) / 100))}
                      renderTrack={Track}
                      renderThumb={Thumb}
                    />
                    <div className="flex space-x-2">
                      <Field
                        name="realisticResidual"
                        type="number"
                        step="1000"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        value={values.realisticResidual}
                        onChange={(e) => setFieldValue('realisticResidual', parseInt(e.target.value, 10))}
                      />
                    </div>
                  </div>
                  <ErrorMessage name="realisticResidual" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
            </div>
            <div className="space-y-4 mt-10">
              <span className="text-xl font-medium font-primary">Bilegenskaper</span>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="fuelConsumption" className="block text-sm font-medium text-gray-700">Bränsleförbrukning (l/mil)</label>
                  <Field name="fuelConsumption" type="number" step="0.1" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
                  <ErrorMessage name="fuelConsumption" component="div" className="text-red-500 text-sm" />
                </div>
                <div>
                  <label htmlFor="yearlyTax" className="block text-sm font-medium text-gray-700">Fordonsskatt (per år)</label>
                  <Field name="yearlyTax" type="number" step="50" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
                  <ErrorMessage name="yearlyTax" component="div" className="text-red-500 text-sm" />
                </div>
                <div>
                  <label htmlFor="monthlyInsurance" className="block text-sm font-medium text-gray-700">Försäkring (per månad)</label>
                  <Field name="monthlyInsurance" type="number" step="50" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
                  <ErrorMessage name="monthlyInsurance" component="div" className="text-red-500 text-sm" />
                </div>
                <div>
                  <label htmlFor="yearlyMiles" className="block text-sm font-medium text-gray-700">Mil per år</label>
                  <Field name="yearlyMiles" type="number" step="100" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" />
                  <ErrorMessage name="yearlyMiles" component="div" className="text-red-500 text-sm" />
                </div>
                </div>
                <div>
                <button type="submit" disabled={isSubmitting} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-accent hover:bg-accent2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Beräkna
                </button>
              </div>
              {Object.keys(financeDetails).length > 0 && (
                <>
                  <button
                    onClick={handleSave}
                    disabled={!isCalculated || !user}
                    className={`mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                  ${!isCalculated || !user ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700 focus:ring-2 focus:ring-offset-2 focus:ring-green-500'}`}
                  >
                    Spara
                  </button>
                  {saveSuccess && (
                    <div className="text-sm text-green-600 mt-4">
                      Beräkningen har sparats!
                    </div>
                  )}
                  {saveError && (
                    <div className="text-sm text-red-600 mt-4">
                      {saveError}
                    </div>
                  )}
                  {!user && (
                    <div className="mt-6 p-2 bg-yellow-100 rounded-md">
                      <span className="text-xs font-medium"><Link to="/login" className='font-semibold'>Logga in</Link> för att spara beräkningar</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {isCalculated && (
        <div className="mt-6 p-4 bg-gray-100 rounded-md">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-lg font-medium font-primary">Total månadskostnad:</span>
              <span className="block text-2xl font-bold">{financeDetails.monthlyTotalCost} kr</span>
            </div>
          </div>
          <div className="">
            <FinanceChart calc={financeDetails} />
          </div>
          <div className="mt-4">
            <FinanceResults calc={financeDetails} />
          </div>
        </div>
      )}
    </div>
  );
}

export default FinanceForm;