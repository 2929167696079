import React from 'react';
import FinanceForm from '../FinanceForm';
import { Hero } from './Hero';
import { Features } from './Features';
import { Testimonials } from './Testimonials';
import BlogList from '../Blog/BlogList';

const Home = () => {

  return (
    <>
    <Hero />
    <Features />
      <div className="container mx-auto p-8" id="calculator">
        <FinanceForm />
      </div>
    <Testimonials />
    <BlogList />
    </>
  );
};

export default Home;
