import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BlogDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    fetch('/data/blogPosts.json')
      .then(response => response.json())
      .then(data => {
        const foundPost = data.find(p => p.slug === slug);
        setPost(foundPost);
        if (foundPost) {
          document.title = foundPost.title;
          document.querySelector('meta[name="description"]').setAttribute('content', foundPost.metaDescription);
          document.querySelector('meta[name="keywords"]').setAttribute('content', foundPost.keywords);
        }
      })
      .catch(error => console.error('Error fetching blog post:', error));
  }, [slug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
    <div className="relative w-full h-64 md:h-96 mb-8">
        <img src={post.heroimg} alt={post.title} className="absolute inset-0 w-full h-full object-cover" />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <h1 className="text-white text-4xl font-semibold">{post.title}</h1>
        </div>
    </div>
    <div className="prose max-w-full">
        <h2 className="text-2xl font-semibold mb-4">{post.subtitle}</h2>
      <div className="prose prose-lg text-gray-700 leading-loose" dangerouslySetInnerHTML={{ __html: post.content }}></div>
      </div>
        </div>
  );
};

export default BlogDetail;

