import axios from 'axios';
import API_BASE_URL from '../config/apiConfig';

const BASE_URL = `${API_BASE_URL}/api/users`; 

const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${BASE_URL}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserFinancials = async (financialData) => {
  const token = localStorage.getItem('token'); // Assuming you store the token in localStorage

  try {
    const response = await axios.post(`${BASE_URL}/update-financials`, financialData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user financials:', error);
    throw new Error(error.response.data.message);
  }
};

export const userService = {
  fetchUserProfile,
  updateUserFinancials
};
