import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { FaEnvelope, FaKey } from 'react-icons/fa';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import API_BASE_URL from '../../config/apiConfig';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const API_URL = `${API_BASE_URL}/api/users/login`;

const Login = () => {
    const location = useLocation();
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [message, setMessage] = useState('');

    const { login } = useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
        setError('');
        setShowForgotPassword(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(API_URL, credentials);
            login(response.data.token);
            navigate('/');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (!error.response) {
                setError('Ingen anslutning till servern');
            } else if (error.response.status === 401) {
                setError('Felaktigt email eller lösenord');
                setShowForgotPassword(true);
            } else {
                setError('Ett oväntad fel uppstod');
            }
            console.error('Login Error', error.response?.data);
        }
    };

    useEffect(() => {
        if (location.state?.success) {
            setSuccessMessage(location.state.success);
            setTimeout(() => setSuccessMessage(''), 10000);
        }
    }, [location.state]);

    useEffect(() => {
        const params = queryString.parse(location.search);
        if (params.success) {
            setMessage(params.success);
        } else if (params.error) {
            setMessage(params.error);
        }
    }, [location.search]);

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-white">
            <div className="w-full max-w-md">
                <form onSubmit={handleSubmit} className="px-8 pt-6 pb-8 mb-4">
                    <h2 className="text-3xl font-semibold mb-5 text-center">Logga in</h2>
                    {loading && <div className="flex justify-center mb-4"><ClipLoader color="#00bfff" /></div>}
                    {successMessage && (
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
                            <span className="block sm:inline">{successMessage}</span>
                        </div>
                    )}
                    {message && (
                        <div className={`alert ${queryString.parse(location.search).success ? 'alert-success' : 'alert-danger'} mb-4`}>
                            {message}
                        </div>
                    )}
                    <div className="mb-4 relative">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <div className="flex items-center">
                            <FaEnvelope className="absolute ml-2 text-gray-500" />
                            <input
                                type="email"
                                name="email"
                                value={credentials.email}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email"
                            />
                        </div>
                    </div>
                    <div className="mb-6 relative">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Lösenord
                        </label>
                        <div className="flex items-center">
                            <FaKey className="absolute ml-2 text-gray-500" />
                            <input
                                type="password"
                                name="password"
                                value={credentials.password}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="password"
                            />
                        </div>
                        {error && <p className="text-red-500 text-xs italic mb-3">{error}</p>}
                        {showForgotPassword && (
                            <p className="text-xs text-blue-500 mb-3">
                                <Link to="/forgot-password" className="font-semibold">Glömt lösenord?</Link>
                            </p>
                        )}
                    </div>
                    <div className="flex items-center justify-between">
                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled={loading}>
                            Logga in
                        </button>
                    </div>
                </form>
                <p className="text-center text-sm mt-4">
                    Ny här? <Link to="/signup" className="font-semibold text-blue-500">Skapa ett konto</Link>
                </p>
            </div>
        </div>
    );
};

export default Login;
