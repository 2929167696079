import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const BlogList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('/data/blogPosts.json')
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(error => console.error('Error fetching blog posts:', error));
  }, []);

  return (
    <section id="blog" className="bg-gray-100 py-20">
      <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-semibold mb-8">Senaste inläggen</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {posts.map(post => (
                    <Link to={`/blog/${post.slug}`} key={post.id} className="block rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
                        <img src={post.heroimg} alt={post.title} className="w-full h-48 object-cover" />
                        <div className="p-4">
                            <h2 className="text-2xl font-semibold mb-2">{post.title}</h2>
                            <p className="text-gray-700">{post.summary}</p>
                            <p className="text-gray-700">{post.description}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>

    </section>
  );
};

export default BlogList;


