import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './index.css';
import FinanceForm from './components/FinanceForm';
import SavedCalculations from './components/SavedCalculations/SavedCalculations';
import Signup from './components/SignUp/SignUp';
import Login from './components/Login/Login';
import Header from '../src/components/Header';  // Make sure this path is correct based on your directory structure
import Home from './components/Home/Home';
import User from './components/User/User';
import { AuthProvider } from './contexts/AuthContext';
import { Footer } from './components/Footer';
import VerifyEmail from './components/VerifyEmail';
import ErrorPage from './components/ErrorPage';

import BlogList from './components/Blog/BlogList';
import BlogDetail from './components/Blog/BlogDetail';

import ResetPassword from './components/PasswordReset';
import PasswordResetRequest from './components/PasswordResetRequest';

import axios from 'axios';
import API_BASE_URL from './config/apiConfig';
import ScrollToTop from './components/ScrollToTop';

axios.defaults.baseURL = API_BASE_URL;
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <ScrollToTop />
        <div className="min-h-95vh">
          <Routes> 
            <Route path="/" element={<Home />} />
            <Route path="/new-car" element={<FinanceForm />} />
            <Route path="/saved-cars" element={<SavedCalculations />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route path="/login" element={<Login />} />
            <Route path="/user" element={<User />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />
            <Route path="/forgot-password" element={<PasswordResetRequest />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<ErrorPage message="404 Not Found" />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
