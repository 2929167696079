import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from '../../services/userService';
import { useAuth } from '../../contexts/AuthContext';
import { BiLogOutCircle } from "react-icons/bi";
import { Tooltip } from 'react-tooltip';

const User = () => {
    const [user, setUser] = useState(null);
    const [cashOnHand, setCashOnHand] = useState('');
    const [monthlyIncome, setMonthlyIncome] = useState('');
    const [currentExpense, setCurrentExpense] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await userService.fetchUserProfile();
                setUser(userData);
                setCashOnHand(userData.cashOnHand);
                setMonthlyIncome(userData.monthlyIncome);
                setCurrentExpense(userData.currentExpense);
            } catch (error) {
                console.error('Error fetching user data', error);
            }
        };

        fetchUser();
    }, []);

    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await userService.updateUserFinancials({
                cashOnHand, monthlyIncome, currentExpense
            });
            setUser({...user, ...response.data});
            alert('Dina ekonomiuppgifter har uppdaterats');
        } catch (error) {
            console.error('Failed to update financial details', error);
            alert('Ett fel uppstod.');
        }
    };

    return (
        <div className="bg-white rounded-lg shadow p-8 m-4 max-w-lg mx-auto">
            {user ? (
                <form onSubmit={handleUpdate} className="space-y-6">
                    <div className="flex justify-between items-center">
                        <button onClick={handleLogout} className="text-white bg-red-500 hover:bg-red-700 transition duration-150 ease-in-out rounded-full p-2">
                            <BiLogOutCircle size="1.5em" />
                        </button>
                    </div>
                    <p className="text-sm text-gray-600">Email: {user.email}</p>
                    <div>
                        <label htmlFor="cashOnHand" className="block text-sm font-medium text-gray-700" data-tooltip-id="cashOnHand-tooltip">Pengar till kontantinstats</label>
                        <input type="number" id="cashOnHand" value={cashOnHand} onChange={(e) => setCashOnHand(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
                            data-tooltip-id="cashOnHand-tooltip"/>
                        <Tooltip id="cashOnHand-tooltip" place="right" effect="solid">Så att du enkelt kan se om du har tillräckligt till kontantinsatsen.</Tooltip>
                    </div>
                    <div>
                        <label htmlFor="monthlyIncome" className="block text-sm font-medium text-gray-700" data-tooltip-id="monthlyIncome-tooltip">Utrymme cashflow per månad</label>
                        <input type="number" id="monthlyIncome" value={monthlyIncome} onChange={(e) => setMonthlyIncome(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            data-tooltip-id="monthlyIncome-tooltip" />
                        <Tooltip id="monthlyIncome-tooltip" place="right" effect="solid">Det du har över varje månad och kan tänka dig att lägga på en bil.</Tooltip>
                    </div>
                    <div>
                        <label htmlFor="currentExpense" className="block text-sm font-medium text-gray-700" data-tooltip-id="currentExpense-tooltip">Nuvarande bilutgift</label>
                        <input type="number" id="currentExpense" value={currentExpense} onChange={(e) => setCurrentExpense(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
                            data-tooltip-id="currentExpense-tooltip"/>
                        <Tooltip id="currentExpense-tooltip" place="right" effect="solid">Så att vi kan jämföra hur din nya bil skiljer sig mot din nuvarande</Tooltip>
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Uppdatera
                        </button>
                    </div>
                </form>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default User;
