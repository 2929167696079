import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config/apiConfig';

const API_URL = `${API_BASE_URL}/api/users/validateToken`; 
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.post(API_URL, {}, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setCurrentUser(response.data.user);
                } catch (error) {
                    console.error('Token validation error:', error);
                    localStorage.removeItem('token'); // Remove invalid token
                    setCurrentUser(null);
                }
            }
            setLoading(false);
        };

        validateToken();
    }, []);

    const login = async (token) => {
        try {
            localStorage.setItem('token', token);
            const response = await axios.post(API_URL, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.data.user) {
                setCurrentUser(response.data.user);
            } else {
                localStorage.removeItem('token');
                throw new Error('Token validation failed after login.');
            }
        } catch (error) {
            throw error; // Bubble up any error to the calling component
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setCurrentUser(null);
    };

    const value = {
        currentUser,
        login,
        logout,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
