// frontend/src/services/financeService.js
import axios from 'axios';
import API_BASE_URL from '../config/apiConfig';

const API_URL = `${API_BASE_URL}/finance`; 


// Post new finance details
// Function to save finance details with the authentication token
export const saveFinanceDetails = async (data) => {
  try {
      const response = await axios.post(API_URL, data, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming the token is stored in localStorage
          }
      });
      return response.data;
  } catch (error) {
      throw new Error(error.response.data.message);
  }
};


//fetch
export const fetchSavedCalculations = async () => {
  const token = localStorage.getItem('token'); // get token from local storage

  try {
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch saved calculations:', error.response.data);
  }
};


//delete
export const deleteFinanceDetail = async (id) => {
  const token = localStorage.getItem('token'); // Get token from local storage

  try {
      const response = await axios.delete(`${API_URL}/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      return response.data;
  } catch (error) {
      throw new Error('Failed to delete finance detail:', error.response.data);
  }
};