import React, { useState } from 'react';
import axios from 'axios';
import { FaEnvelope, FaKey } from 'react-icons/fa';
import API_BASE_URL from '../../config/apiConfig';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

const API_URL = `${API_BASE_URL}/api/users/signup`;

const Signup = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        return regex.test(password);
    };

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validatePassword(userData.password)) {
            setError('Lösenordet behöver vara minst 8 tecken långt, en stor bokstav, och en siffra.');
            return;
        }
        setLoading(true);
        try {
            await axios.post(API_URL, userData);
            setLoading(false);
            navigate('/login', { state: { success: 'Tack för att du registrerar dig! Kolla i din inkorg för att verifiera ditt konto.' } });
        } catch (error) {
            setLoading(false);
            const errorMessage = error.response?.data.message || 'Registreringen misslyckades. Försök igen.';
            setError(errorMessage);
            console.error('Signup Error', errorMessage);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-white">
            <div className="w-full max-w-md">
                <form onSubmit={handleSubmit} className="px-8 pt-6 pb-8 mb-4">
                    <h2 className="text-3xl font-semibold mb-5 text-center">Skapa konto</h2>
                    {loading && <div className="flex justify-center mb-4"><ClipLoader color="#00bfff" /></div>}
                    <div className="mb-4 relative">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <div className="flex items-center">
                            <FaEnvelope className="absolute ml-2 text-gray-500" />
                            <input
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email"
                            />
                        </div>
                    </div>
                    <div className="mb-6 relative">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Lösenord
                        </label>
                        <div className="flex items-center">
                            <FaKey className="absolute ml-2 text-gray-500" />
                            <input
                                type="password"
                                name="password"
                                value={userData.password}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="password"
                            />
                        </div>
                        {error && <p className="text-red-500 text-xs italic mb-3">{error}</p>}
                    </div>
                    <div className="flex items-center justify-between">
                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Registrera
                        </button>
                    </div>
                </form>
                <p className="text-center text-sm mt-4">
                    Har du redan ett konto? <Link to="/login" className="font-semibold text-blue-500">Logga in</Link>
                </p>
            </div>
        </div>
    );
};

export default Signup;
