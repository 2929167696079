import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path as needed
import { BiLogInCircle, BiUserCircle, BiBookmarks, BiPlusCircle } from "react-icons/bi";

const Header = () => {
    const { currentUser } = useAuth();

    return (
        <header className="bg-primary text-white p-4 min-h-5vh">
            <nav className="container mx-auto flex justify-between items-center px-4 sm:px-8 lg:px-16">
                {/* Left aligned items */}
                <div className="text-2xl font-bold font-primary">
                    <Link to="/" className="hover:text-accent transition duration-150 ease-in-out">Carculated</Link>
                </div>

                {/* Right aligned items */}
                <ul className="flex space-x-6 items-center">
                    {currentUser ? (
                        <>
                            <li>
                                <Link to="/new-car" className="hover:text-accent transition duration-150 ease-in-out">
                                    <BiPlusCircle size="1.5em" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/saved-cars" className="hover:text-accent transition duration-150 ease-in-out">
                                    <BiBookmarks size="1.5em" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/user" className="hover:text-accent transition duration-150 ease-in-out">
                                    <BiUserCircle size="1.5em" />
                                </Link>
                            </li>
                        </>
                    ) : (
                        <li>
                            <Link to="/login" className="hover:text-accent transition duration-150 ease-in-out">
                                <BiLogInCircle size="1.5em" />
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
