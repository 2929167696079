import React from 'react';

export const Hero = () => {
  return (
    <section id="hero" className="relative text-center py-20 min-h-95vh flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: "url('/img/volvo-interior.jpg')" }}>
      <div className="absolute inset-0 bg-primary opacity-70"></div> {/* Overlay */}
      <div className="relative px-4">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 text-white font-primary">Beräkna dina bilkostnader enkelt</h1>
        <p className="text-xl md:text-2xl mb-8 text-accent3">Få detaljerade uppskattningar för försäkring, skatt, bränsle och mer med bara några klick.</p>
        <a href="#calculator" className="bg-accent text-white py-3 px-6 rounded-full text-lg hover:bg-accent2">Börja räkna nu</a>
      </div>
    </section>
  );
};
